.member-card{
    background: var(--main-bg);
    border-radius: 0.5rem;
    border: 1px solid #222;
    position: relative;
    cursor: pointer;

    .member-card-content{
        display: block;
        padding: 1rem;
    }

    .only-hover{
        display: none;
    }

    &:hover{
        background-color: #222;
        border: 1px solid #333;

        .only-hover{
            display: flex;
        }

        h6{
            color: #999 !important;
        }
    }
}

.member-modal {

    
    .btn-modal-save-to-list {
        transition: .25s;
        cursor: pointer;
        color: #CCC;
        background: #222;
        border-radius: 4px;
        padding: 6px 10px;
        font-weight: 500;
        font-size: 12px;

        &:hover{
            // background: var(--highlight);
            background: var(--main-dark-color);
            color: #FFF;
        }
    }

}
    
.inf-card {
    display: flex;
    background: #F4F4FF;
    border-radius: 25px;
    min-height: calc(100vh - 140px);
    padding: 1.5rem;

    .profile {
        border-radius: 50%;
        width: 35%;
        object-fit: contain;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .title {
        font-size: 24px;
        color: black;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .social-accounts{

        table {
            width: 100%;
            table-layout: fixed;
        }

        table tr{
            display: flex;
        }
        
        table tr td {
            flex: 1;
        }
        
        table tr td:nth-child(2) {
            width: 100%;
        }
        
        h6{
            color: #444;
            font-size: 13px;
            font-weight: 500;
        }
        
        h5{
            color: #222;
            font-size: 15px;
            font-weight: bold;
        }
    }

    .username {
        color: #999;
        margin-bottom: 10px;
        font-weight: 200;
    }
    
    .username img {
        height: 12px;
        margin-left: 15px;
        opacity: 0.5;
    }
    
    .boxes {
        text-align: center;
        padding: 10px;
    }
    
    
    .boxes h5 {
        color: black;
        font-weight: bold;
        margin: 3px;
        font-size: 22px;
        text-align: center;
    }
    
    .boxes h6 {
        color: #333;
        font-weight: 500;
        margin-bottom: 0px;
    }
    
    .boxes > div {
        padding: 6px;
    }
    
    .boxes > div > div {
        background: #E7E6FF;
        height: 120px;
        border-radius: 20px;
        display: flex;
        align-items: center;
    }
    
    .boxes > div > div > div {
        text-align: center;
        width: 100%;
    }
    
    .boxes > div:nth-child(2) > div {
        /*    background: #3850D8;*/
        background: linear-gradient(145deg, #6F3FEB 1%, #4E21E6 70%);
    }
    
    .boxes > div:nth-child(2) > div * {
        color: #FFF;
    }

}

.save-list-dropdown{
    padding: 5px;
    position: absolute;
    width: 100%;
    background: #FFF;
    border-radius: 0px 0px 6px 6px;
    font-weight: 600;
    font-size: 11px;
    z-index: 999;
    max-height: 130px;
    overflow-y: auto;

    .dropdown-item{
        padding: 4px 8px;
        cursor: pointer;
    }

    .dropdown-item:hover{
        border-radius: 4px;
        background: #EBF5FE;
    }
}

.main-body#member .legislations{
    max-height: 700px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 10px;
        background: #222;
    }

    &::-webkit-scrollbar-thumb {
        background: #333;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #222;
    }
}

.main-body#member .terms, .main-body#member .party-history{
    max-height: 400px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 10px;
        background: #222;
    }

    &::-webkit-scrollbar-thumb {
        background: #333;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #222;
    }
}


