.main-body#lists{

    .lists-wrapper{
        flex: 1;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 10px;
            background: #222;
            border-radius: 4px;
        }
    
        &::-webkit-scrollbar-thumb {
            background: #333;
            border-radius: 4px;
        }
    
        &::-webkit-scrollbar-thumb:hover {
            background: #222;
        }

        .list-card{
            background-color: var(--alt-color);
            border: 1px solid #222;
            padding: 2rem;
            border-radius: 0.5rem;
            transition: .25s;

            &:hover{
                cursor: pointer;
                background-color: var(--main-color);

                h5{
                    color: var(--alt-color);
                }
            }
        }
    }

    .btn-list-item-wrapper{
        background: #CCC;
        padding: 1px;    
        border-radius: 11px;
        margin-bottom: 1.25rem;
    }
    .btn-list-item{    
        height: 100%;
        border-radius: 10px;
        background-color: #FFF;
        color: #333;
        transition: 350ms ease;
        font-size: 15px;
        line-height: 1em;
        font-weight: 400;
        border: none;
        padding: .75rem 1rem;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    .btn-list-item:hover{    
        background: var(--alt-color);
    }

    .btn-list-item.active{    
        background: var(--alt-color);
    }

    .btn-list-item-wrapper.active{    
        background: linear-gradient(to right, #D5B4F9 10%, #4E21E6);
    }

    .btn-new-list{
        border-radius: 30px;
        background-color: var(--main-color);
        color: #FFF;
        transition: 350ms ease;
        font-size: 15px;
        line-height: 1em;
        font-weight: 300;
        padding: .65rem 1.5rem .65rem .65rem;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    .btn-new-list:hover{    
        background: #4E21E6;
    }
    
    .btn-remove {
        opacity: 0.5;
        transition: all 0.25s;
        cursor: pointer;
        color: #ef4444;
        border: 1px solid #ef4444;
        background: transparent;
        border-radius: 20px;
        padding: 10px 20px;
        font-weight: 600;
        font-size: 13px;
    }
    
    .btn-remove:hover {
        opacity: 1;
    }

    
    @media (max-width: 768px) {

        .lists-wrapper{
            .list-card{
                padding: 1.25rem;
            }
        }

    }
}