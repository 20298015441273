.modal {
    .modal-header {
        background: var(--main-color);
    }

    .modal-content,
    .modal-dialog {
    }

    .modal-content {
        display: flex;
        flex-direction: column;
    }

    .modal-body {
        overflow-y: auto;
        flex: 1 1;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .modal-header .modal-title {
    }

    label{
        color: white;
    }

    .form-control{
        margin-top: 5px;
    }
}