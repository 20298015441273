.table-wrapper {

    .table{
        flex: 1 1;
        width: 100%;

        tbody{

            tr{
    
                &:hover{
                    background-color: var(--alt-color);
                }
            }
        }
        
        thead {
            position: sticky;
            top: 0;
            background: #FFF;
            // z-index: 1;
        }

        th{
            background: var(--main-color);
            font-size: 13px;
            font-weight: 500;
            letter-spacing: .25px;
            background: transparent;
            border-top: none;
            color: #111;
            border-bottom: 1px solid #555;
            padding: 0.65rem;
            width: auto;
            text-align: center;
        }
    
        th:first-child,
        td:first-child {
            transition: .25s;
            text-align: left;
            padding-left: 0px;
        }
    
        th:last-child,
        td:last-child {
            text-align: right;
            padding-right: 0px;
        }
        
        td {
            text-align: center;
            font-weight: 600;
            padding: 1.7rem .25rem;
            border-bottom: 1px solid #e9e9e9;
        }
    }

}

@media screen and (max-width: 768px) {
    .table{
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table td, .table th{
        white-space: nowrap;
        vertical-align: middle;
    }
}