.datatable {
    
    .table{
        width: 100%;
        border: 1px solid #222;
        border-radius: 5px;
    }

    .table-wrapper .table-search{
        background: #111;
        height: auto;
        min-height: 2rem;
        border: 0.0625rem solid #222;
        color: #CCC;
        border-radius: 0.25rem;
        padding: 8px 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        transition: all .3s;

        &::placeholder{
            color: #666;
        }
    }

    .table-wrapper .table-search:focus-visible, .table-wrapper .table-search:focus, .table-wrapper .table-search:hover {
        border-color: #333;
        box-shadow: none;
        outline: none;
    }

    .table-wrapper select.rows{
        padding: 8px 8px;
        background-color: #111;
        border: 0.0625rem solid #222;
        color: #666;
        font-weight: 400;
        font-size: 13px;
        border-radius: 5px;
    }

    .table-wrapper .record-range{
        font-size: 13px;
        font-weight: 500;
        color: #555;
        letter-spacing: .25px;
    }

    .table-wrapper .pagination{
        display: flex;
    }

    .table-wrapper .pagination button{
        padding: 5px 3px;
        background-color: #111;
        border: 1px solid #222;
        font-size: 12px;
        border-radius: 0px;
        margin-right: 3px;
    }

    .table-wrapper .pagination button.cur{
        padding: 5px 7px;
        letter-spacing: .25px;
        font-weight: 500;
    }

    .table-wrapper .table th {
        border-bottom: 1px solid #FFF;
        font-size: .75rem;
        font-weight: 600;
        letter-spacing: 0px;
        color: #555;
        padding: .75rem 1rem;
        text-align: left;
    }

    .table-wrapper .table td {
        color: #D6D6D6;
        border-bottom: 0.0625rem solid #222;
        font-weight: 500;
        font-size: .8rem;
        letter-spacing: normal;
        padding: .75rem 1rem;
        text-align: left;
    }

    .tbl-details{
        width: 100%;
        border: 1px solid #f0f0f0;
    }

    .tbl-details th {
        border-bottom: 1px solid #f0f0f0;
        font-size: .75rem;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: .25px;
        color: #555;
        padding: .75rem 1rem;
        text-align: left;
    }

    .tbl-details td {
        color: #999;
        border-bottom: 0.0625rem solid #f0f0f0;
        font-weight: 500;
        font-size: .9rem;
        letter-spacing: normal;
        padding: .75rem 1rem;
    }

    @media screen and (max-width: 768px) {
        .table{
            display: block;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
        }

        .table td, .table th{
            white-space: nowrap;
            vertical-align: middle;
        }
    }

}