body, html {
    background: var(--alt-color);
}

#wrapper {
    background: var(--alt-color);
}

#sidebar-wrapper {
    background: var(--main-bg);
    border-radius: 0px;
    height: 100vh;
    width: 16rem;
    position: fixed;
    z-index: 2;
    transition: margin .25s ease-out;

    .sidebar-heading {
        width: 100%;
        margin-top: 5px;
        margin-bottom: 10px;
        margin-bottom: 0px;
        padding: 1rem 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img.logo {
            width: 14rem;
            object-fit: contain;
            margin-bottom: 0px;
        }
    }

    .nav-main {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.25px;
        padding: 14px 8px;
        border-radius: 6px;
        color: #999;

        &:hover {
            background: rgba(255, 255, 255, 0.1);
            color: #FFF;
        }

        &.collapsed:hover {
            color: #111;
        }

        &.active-link {
            background: var(--main-color);
            font-weight: 500;

            * {
                color: #111;
            }

            svg:first-child {
                stroke: #000;
            }

            .nav-icon{
                background: var(--main-color);
            }
        }

        .nav-hide {
            display: flex;
            align-items: center;
        }

        .nav-icon {
            display: none;
        }


        svg {
            margin-right: 8px;
            width: 35px;
            height: 16px;
            padding-left: 10px;
        }
    }

    .dropdown-menu{
        div{
            a{
                display: flex;
                align-items: center;

                svg{
                    margin-right: 10px;
                }
            }
        }
    }

    .list-group {
        min-height: calc(100vh - 125px);
        position: relative;
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;

        > div {
            width: 100%;

            &:first-child {
                margin-bottom: 50px;
                flex-shrink: 1;
                flex-grow: 1;
                height: auto;
                position: relative;
                overflow-y: hidden;
            }

            &:nth-child(2) {
                margin-bottom: 5px;
            }
        }

        p {
            color: #515161;
            font-weight: 600;
            font-size: 0.75rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom: 8px;
            padding-left: 10px;
        }
    }

    .notif-badge {
        width: 25px;
        height: 18px;
        padding: 4px;
        text-align: center;
        font-size: 12px;
        line-height: 10px;
        font-weight: 600;
        background: #FFF;
        border-radius: 20px;
        color: #000;
    }

    .notification {
        position: relative;

        .notif {
            position: absolute;
            width: 100%;
        }

        .notif-badge {
            position: absolute;
            width: 25px;
            height: 18px;
            top: 1px;
            right: 2px;
            padding: 4px;
            text-align: center;
            font-size: 12px;
            line-height: 10px;
            font-weight: 600;
            background: #FFF;
            border-radius: 20px;
            color: #000;
        }
    }

    .user-profile {
        margin-left: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        cursor: pointer;
        transition: 0.25s;
        width: 90%;

        img {
            height: 50px;
            width: 50px;
            padding: 10px;
            object-fit: contain;
            box-shadow: 0 0 4px 2px #cccccc50;
            border: 1px solid #cccccc90;
            border-radius: 50%;
        }

        div {
            width: 65%;
            padding-left: 10px;
            padding-top: 3px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            h6 {
                font-size: 14px;
                font-weight: 700;
                color: #222;
                margin-bottom: 2px;
            }

            p {
                font-size: 12px;
                font-weight: 500;
                color: #222;
                margin: 0;
                opacity: 0.7;
            }
        }
    }
}

#wrapper.toggled .hide-in-toggled {
    display: none;
}

#page-content-wrapper {
    transition: margin .25s ease-out;

    #page-content {
        overflow-y: hidden;
        height: 100%;
        min-height: calc(100vh - 1rem);
        transition: margin .25s ease-out;
        margin-right: auto;
        box-shadow: rgb(76 78 100 / 30%) 0px 1px 6px 0px;
        background: #000;

        nav {
            padding: .75rem 1.5rem;

            input#search-navbar {
                background: var(--highlight);
                border-radius: 10px;

                &:focus, &:hover {
                    background: #D6D6FF90;
                    outline: none;
                    border: none;
                }

                &::placeholder {
                    color: #999;
                }
            }

            .page-head {
                line-height: 1;
                font-size: 26px;
                color: #FFF;
                font-weight: 700;
                letter-spacing: 0.25px;
                margin-bottom: 0px;
                margin-left: 8px;
            }
        }

        .main-header {
            height: 125px;
            border-bottom: 1px solid #eae8f2;
        }

        .main-body {
            height: 100%;
            height: calc(100vh - 110px);
            border-top-left-radius: 10px;
            // background: #FFF;
            padding-top: 00px;
            padding-bottom: 0px;
            overflow-y: auto;
            -ms-overflow-style: none; 
            scrollbar-width: none; 
        }
        .main-body > div{
            height: 100%;
        }
    }
}


.main-body {

    .section{
        
    }
}


.btn-new,
.btn-submit,
.btn-cancel,
.btn-delete,
.btn-delete-inv,
.btn-edit,
.btn-submit-inv {
    border-radius: 6px;
    transition: 350ms ease;
    font-size: 14px;
    line-height: 1em;
    text-align: center;

    &.btn-new {
        display: flex;
        align-items: center;
        background-color: var(--alt-color);
        color: var(--main-color);
        font-weight: 500;
        border: 1px solid var(--main-color);
        padding: .75rem 1rem;

        &:hover {
            background-color: var(--main-color);
            color: var(--alt-color);
        }
    }

    &.btn-submit {
        display: flex;
        background-color: var(--main-color);
        color: var(--alt-color);
        font-weight: 500;
        border: 1px solid var(--main-color);
        padding: .85rem 1.25rem;

        &:hover {
            background-color: var(--main-dark-color);
        }

        &:disabled {
            cursor: no-drop !important;
            background-color: #e1e1e1;
            border: 1px solid #e1e1e1;
            color: #777;
        }
    }

    &.btn-cancel {
        background-color: transparent;
        color: #c9c9c9;
        font-weight: 500;
        border: 1px solid #c9c9c9;
        padding: .85rem 1.25rem;

        &:hover {
            background-color: #c9c9c9;
            color: #FFF;
        }
    }

    &.btn-delete {
        background-color: #FF2929;
        color: #FFF;
        font-weight: 500;
        border: 0px;
        padding: .85rem 1.25rem;

        &:hover {
            background-color: #E00000;
        }
    }

    &.btn-delete-inv {
        background-color: transparent;
        color: #FF2929;
        font-weight: 400;
        border: 1px solid #FF2929;
        padding: .85rem 1.25rem;

        &:hover {
            background-color: #FF2929;
            color: #FFF;
        }
    }

    &.btn-edit {
        background-color: var(--alt-color);
        color: #666;
        font-weight: 500;
        border: 0px;
        padding: .85rem 1.15rem;
        border: 1px solid #555;

        &:hover {
            background-color: #222;
            color: #999;
        }
    }

    &.btn-submit-inv {
        background-color: #FFF;
        color: #111;
        font-weight: 500;
        border: 1px solid #111;
        padding: 1rem 1.25rem;

        &:hover {
            box-shadow: rgb(76 78 100 / 56%) 0px 6px 18px -8px;
            background-color: #000;
            color: #fff;
        }
    }
}

.btn-main-nav {
    border-radius: 10rem;
    transition: 350ms ease;
    background-color: var(--main-color);
    border: 1px solid var(--main-color);
    color: #111;
    font-size: 12px;
    line-height: 1;
    font-weight: 600;
    padding: 0.75rem 1.15rem;
    margin: 0.25rem 0rem;
    text-transform: capitalize;

    &:hover {
        background-color: #FFF;
        color: #000;
    }
}

.icon-main {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    font-size: 1.25rem;
    line-height: 1;
    border-radius: 8px;
    color: #52459D;
    background-color: rgba(82, 69, 157, 0.22);

    &.icon-main-warning {
        background: #A6A6E2;
        color: #51449E;
        border-radius: 50%;
    }

    &.icon-main-danger {
        background: #A6A6E2;
        color: #FC2318;
        border-radius: 50%;
    }
}

.stat-card {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 250px;
    padding: 2rem;
    display: flex;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;

    .stat-title,
    .stat-content {
        width: 100%;
    }

    .stat-title {
        margin-top: 5px;
        flex: 0 0 auto;
    }

    .stat-content {
        display: flex;
        align-items: flex-end;
        flex: 0 0 auto;
    }

    .title {
        color: #000;
        font-weight: bold;
        font-size: 1.15rem;
    }

    .sub-title {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.15px;
        color: #999;
        margin-bottom: 3px;
    }

    h5 {
        margin-bottom: 5px;
        font-size: 20px;

        &.text-warning {
            color: #ED8C00 !important;
        }
    }

    .btn-main-warning,
    .btn-main-success {
        width: 100%;
        padding: 0.45rem 0.3rem;
        border-radius: 40px;
        font-size: .8rem;
        font-weight: 600;
        text-align: center;

        &.btn-main-warning {
            color: #000;
            background-color: #FFD85F;
            border-color: #FFD85F;

            &:hover {
                color: #FFF;
                background-color: #FFD825;
                border-color: #FFD825;
            }

            i {
                transform: rotate(-45deg);
            }
        }

        &.btn-main-success {
            color: #FFF;
            background-color: #257052;
            border-color: #257052;

            &:hover {
                background-color: #1F5C43;
                border-color: #1F5C43;
            }
        }
    }
}

.text-delete,
.text-edit {
    cursor: pointer;
    opacity: 0.7;
    font-weight: 500;
    padding: 0px 2px;

    &:hover {
        opacity: 1;
    }

    &.text-edit {
        color: #0062cc !important;
    }

    &.text-delete {
        color: #c82333 !important;
    }
}

.tooltip {
    pointer-events: none;
}

/* Media Queries */
@media (min-width: 768px) {
    #wrapper .nav-main {
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between !important;
        align-items: center;
    }

    #wrapper.toggled {
        .sidebar-heading{
            visibility: hidden;
        }
    }

    #wrapper.toggled .nav-hidex {
        display: none;
    }

    #wrapper.toggled .nav-hide {
        // width: 3rem;
        align-items: end;
        margin-left: auto;
    }

    #wrapper.toggled .nav-hide span{
        display: none;
    }

    #wrapper.untoggled .menu .submenu .nav-main {
        padding: 9px 0px 9px 45px;
    }

    #wrapper.toggled .nav-main {
        // padding: 0;
        // font-size: 0;
        width: 3rem;
        margin-left: auto;
    }

    #wrapper.toggled .dropdown-menu {
        width: 3rem;
        margin-left: auto;
    }

    #wrapper.toggled .nav-icon {
        transition: 0.25s;
        margin-left: auto;
        width: 3rem;
        // display: block;
        text-align: right;
        // padding: 17px 10px;
        // border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    #wrapper.toggled{
        .dropdown-menu{
            div{
                a{
                    span{
                        display: none;
                    }

                    svg{
                        margin-right: 0px;
                    }
                }
            }
        }
    }

    #page-content-wrapper {
        min-width: 0;
        border-radius: 20px;
        margin-left: calc(16rem);
        padding: .5rem .5rem .5rem 0rem;

        #page-content {
            border-radius: 20px;
        }
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: -13rem;
    }

    #wrapper.toggled #page-content-wrapper {
        margin-left: 3rem;
    }

    .main-body,
    .main-header {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

@media (max-width: 768px) {
    #wrapper.toggled .nav-main {
        padding: 12px 0;
        display: flex;
        justify-content: space-between !important;
    }

    #menu-toggle {
        cursor: pointer;
        width: 30px;
        height: 45px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    #menu-toggle-sm {
        position: absolute;
        z-index: 1;
        filter: invert(1);
        cursor: pointer;
        width: 10px;
        height: 25px;
        top: 23px;
        left: 15px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .page-head {
        // margin-left: 1.5rem !important;
    }

    #sidebar-wrapper {
        margin-left: -16rem !important;
    }

    #sidebar-wrapper .sidebar-heading{
        margin-top: 0px;
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: 0 !important;
        position: fixed;
        border-right: 1px solid #222;
        width: 100%;
    }

    #page-content-wrapper .main-header {
        height: auto;
    }
    
    .main-body, .main-header, #page-content-wrapper #page-content nav {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    #page-content-wrapper #page-content{
        min-height: 100%;
        display: flex;
        flex-direction: column;
    }

    .main-body{
        height: 100% !important;
        flex: 1 1;
    }

    html, body, div#root, #wrapper, #page-content-wrapper{
        height: 100%;
    }

    
}
