@font-face {
    font-family: 'Euclid Circular A';
    font-style: normal;
    font-weight: normal;
    font-display: auto;
    src: url('../fonts/euclidcirculara-regular.eot');
    src: url('../fonts/euclidcirculara-regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EuclidCircularA-Regular.woff2') format('woff2'),
        url('../fonts/EuclidCircularA-Regular.woff') format('woff'),
        url('../fonts/EuclidCircularA-Regular.ttf') format('truetype'),
        url('../fonts/euclidcirculara-regular.svg#EuclidCircularA') format('svg');
}

@font-face {
    font-family: 'Euclid Circular A';
    font-style: normal;
    font-weight: 200;
    font-display: auto;
    src: url('../fonts/euclidcirculara-light.eot');
    src: url('../fonts/euclidcirculara-light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/euclidcirculara-light.woff2') format('woff2'),
        url('../fonts/euclidcirculara-light.woff') format('woff'),
        url('../fonts/euclidcirculara-light.ttf') format('truetype'),
        url('../fonts/euclidcirculara-light.svg#EuclidCircularA') format('svg');
}

@font-face {
    font-family: 'Euclid Circular A';
    font-style: normal;
    font-weight: 600;
    font-display: auto;
    src: url('../fonts/euclidcirculara-medium.eot');
    src: url('../fonts/euclidcirculara-medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/euclidcirculara-medium.woff2') format('woff2'),
        url('../fonts/euclidcirculara-medium.woff') format('woff'),
        url('../fonts/euclidcirculara-medium.ttf') format('truetype'),
        url('../fonts/euclidcirculara-medium.svg#EuclidCircularA') format('svg');
}

@font-face {
    font-family: 'Euclid Circular A';
    font-style: normal;
    font-weight: bold;
    font-display: auto;
    src: url('../fonts/euclidcirculara-bold.eot');
    src: url('../fonts/euclidcirculara-bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/euclidcirculara-bold.woff2') format('woff2'),
        url('../fonts/euclidcirculara-bold.woff') format('woff'),
        url('../fonts/euclidcirculara-bold.ttf') format('truetype'),
        url('../fonts/euclidcirculara-bold.svg#EuclidCircularA') format('svg');
}
