.main-body#discover, .main-body#lists, .main-body#list-details{
    .page-head {
        margin-top: 0px !important;
    }

    .btn-accent {
        border-radius: 10px;
        background-color: #f422ff;
        color: #FFF;
        transition: 350ms ease;
        font-size: 14px;
        line-height: 1em;
        font-weight: 500;
        text-align: center;
        border: 1px solid #f422ff;
        padding: .25rem 1rem;
        height: 40px;
    }
    
    .btn-accent:hover {
        box-shadow: rgb(76 78 100 / 56%) 0px 6px 18px -8px;
        background-color: #000;
        color: #fff;
    }

    .allrecords {
        position: relative;
    }

    .members-records {
        grid-auto-rows: min-content;
        flex: 1;
        overflow-y: auto;
        position: relative;
        -ms-overflow-style: none; 
        scrollbar-width: none; 
      }
  
      .members-records::-webkit-scrollbar {
        // display: none;
      }
    
    .allrecords .overlayx {
        background: linear-gradient(180deg, rgba(23, 218, 184, 0) 0%, rgba(0, 0, 0, 0.1) 90%);
        height: 100%;
        width: 100%;
        position: absolute;
    }
    
    .inf-filters .search {
        position: relative;
    }
    
    .inf-filters .search .filters-input {
        font-size: 14px;
        background: var(--main-bg);
        border: 1px solid #222 !important;
        border-radius: 5px;
        height: 44px;
        padding-left: 45px;
        width: 100%;
        border: none;

        &:focus, &:hover{
            background: #222;
            outline: none;
            border: none;
        }

        &::placeholder{
            color: #999;
        }
    }
    
    .inf-filters .search i,
    .inf-filters .search img {
        top: 12px;
        left: 15px;
        position: absolute;
        opacity: .8;
    }
    
    .inf-filters .filters .filters-icon {
        opacity: .4;
        font-size: 22px;
        margin-right: 5px;
    }
    
    .inf-filters .filters > div > div:not(:nth-child(1)) {
        // margin-left: 15px;
    }
    
    .inf-filters .filters .filters-select > div {
        margin-bottom: 12px;
    }
    
    .inf-filters .filters .bootstrap-select {
        width: auto !important;
    }
    
    .inf-filters .filters .bootstrap-select button {
        border-radius: 50px !important;
        height: 40px;
        border: 1px solid #F4F4FF;
        background: #F4F4FF;
        font-size: 14px;
        color: #555;
        font-weight: 600;
        padding: 8px 30px 10px 20px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url("https://img.icons8.com/ios-glyphs/30/expand-arrow--v1.png") #F4F4FF no-repeat 98.5% !important;
        background: url("https://img.icons8.com/ios-glyphs/30/expand-arrow--v1.png") #F4F4FF no-repeat calc(100% - 10px) !important;
        background-size: 13px 13px !important;
    }
    
    .inf-filters .filters .bootstrap-select button:active,
    .inf-filters .filters .bootstrap-select button:focus {
        outline: none !important;
    }
    
    .inf-filters .filters .bootstrap-select button:after {
        display: none;
    }
    
    .inf-filters .filters select option {
        font-weight: 400;
        min-height: 1.75em;
        padding: 5px 6px;
        color: #333;
        border-radius: 10px;
    }
    
    .inf-filters .filters select::before {
        font-family: "Font Awesome 6 Free";
        font-weight: 900;
        content: "\f107";
    }
    
    #table2_wrapper > .row:nth-child(1) {
        display: none;
    }
    
    #table2_wrapper > .row:nth-child(3) .col-md-5 {
        display: none;
    }
    
    #table2_wrapper > .row:nth-child(3) .col-md-7 {
        margin-top: 30px;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    
    #table2_paginate {
        display: none;
    }
    
    
    .gradient-text {
        background-color: #3850D8;
        background-image: #3850D8;
        background-size: 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
    }
    
    
    .inf-table tr {
        cursor: pointer;
        border-radius: 10px;
        border: none;
    }
    
    .inf-table tbody tr.active-inf {
        background: #F4F4FF;
    }
    
    .inf-table tbody tr:hover {
        background: #F4F4FF;
    }
    
    .inf-table tr td:first-child {
        border-radius: 10px 0px 0px 10px;
    }
    
    .inf-table tr td:last-child {
        border-radius: 0px 10px 10px 0px;
    }
    
    .inf-table th {
        font-size: 13px;
        font-weight: 600;
        letter-spacing: .25px;
        background: transparent;
        border-top: none;
        color: #111;
        border-bottom: 1px solid #555;
        text-transform: capitalize;
        padding-right: 0.75rem !important;
        width: auto !important;
    }
    
    .inf-table th,
    .inf-table td {
        text-align: center;
    }
    
    .inf-table th:nth-child(1),
    .inf-table td:nth-child(1) {
        text-align: left;
    }
    
    .inf-table td {
        font-weight: 600;
        border: none;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }
    
    .inf-table td.name {
        line-height: 18px;
        color: black;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        align-items: center;
    }
    
    .inf-table td.name img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        margin-right: 10px;
    }
    
    .inf-table td.name .img-placeholder {
        display: flex;
        height: 50px;
        width: 50px;
        justify-content: center;
        align-items: center;
        background-color: rgb(245 245 245);
        border-radius: 50%;
        margin-right: 10px;
    }

    .inf-table td.name .img-placeholder span{
        font-size: 20px;
    }
    
    .inf-table td.name span {
        font-weight: 500;
        font-size: 12px;
        letter-spacing: .25px;
        color: #999;
    }
    
    .inf-table td.profiles img {
        height: 22px;
        width: 22px;
        object-fit: contain;
    }
    
    .inf-table td.location {
        line-height: 16px;
    }
    
    .inf-table td.location span {
        font-weight: 600;
        font-size: 12px;
        letter-spacing: .25px;
        color: #000;
        display: block;
    }
    
    .inf-table td.location span:nth-child(2) {
        font-weight: 500;
        color: #999;
        font-size: 11px;
    }
    
    .inf-table td.followers {
        color: #333;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 0.25px;
    }
    
    .inf-table td.likes {
        color: #333;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 0.25px;
    }
    
    table.dataTable > thead .sorting:before,
    table.dataTable > thead .sorting:after {
        display: none !important;
    }
    
    .load-more {
        font-weight: 600;
        color: #333;
        background: #F4F4FF;
        border-radius: 30px;
        font-size: 14px;
        padding: 8px 15px;
    }
    
    .load-more i {
        margin-left: 5px;
        font-size: 12px;
    }
    
    .sections {
        margin-bottom: 10px;
        overflow-x: hidden;
        height: 100%;
    }
    
    .section-left {
        height: calc(100vh - 120px);
        overflow-y: hidden;
        display: flex;
        flex-direction: column;
        position: relative;
    }
      
    .section-left::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30%;
        // background: linear-gradient(to top, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
        pointer-events: none; 
    }

    .inf-table-wrapper {
      flex: 1;
      overflow-y: auto;
      position: relative;
      -ms-overflow-style: none; 
      scrollbar-width: none; 
    }

    .inf-table-wrapper::-webkit-scrollbar {
      display: none;
    }

    .inf-table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
    }
      
  
    .inf-table thead {
      position: sticky;
      top: 0;
      background: white; /* Background color for the header */
      z-index: 1;
    }
  
    .inf-table th {
      padding: 1rem;
      text-align: center;
    }
  
    .inf-table td {
      padding: .5rem .75rem;
      text-align: center;
    }
  
    .inf-table tr th:first-child {
      text-align: left;
    }
  
    .inf-table tr td:first-child {
      text-align: left;
    }

    .inf-table th {
      box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.1);
    }
    
    .section-right {
        padding-left: 30px;
        transition: .25s;
        margin-right: -40%;
        width: 35%;
    }
    
    .sections.toggled {}
    
    .sections.toggled .section-left {
        width: 65%;
        transition: .25s;
    }
    
    .sections.toggled .section-right {
        margin-right: 0%;
        padding-left: 30px;
        transition: .25s;
    }
    
    .btn-save-to-list {
        cursor: pointer;
        color: var(--alt-color);
        background: var(--main-color);
        border-radius: 3px;
        padding: 4px 8px;
        font-weight: 500;
        font-size: 11px;

        &:hover{
            // background: var(--highlight);
            background: var(--main-dark-color);
        }
    }
    
    .other-profiles {
        color: #111;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
    }
    
    .other-profiles a:not(:nth-child(1)) {
        margin-left: 8px;
    }
    
    .bg-lightblue td {
        background: #F4F4FF;
    }
    
    .dataTables_info {
        color: #B6B6C1 !important;
    }
    
    .page-item.disabled .page-link {
        color: #B6B6C1;
    }
    
    .page-link {
        border: none;
        background: #F4F4FF;
        border-radius: 30px;
        padding: 0.5rem 0.9rem;
        color: #3850D8;
        font-size: 14px;
    }
    
    .page-item.active .page-link {
        background-color: #3850D8;
        background-size: 100%;
        background-repeat: repeat;
        border: none;
    }
    
    .pagination .page-item:not(:first-child) {
        margin-left: 10px;
    }
    
    .page-item.previous .page-link,
    .page-item.next .page-link {
        background: #F4F4FF;
        border-radius: 30px;
        padding: 0.5rem 0.9rem;
    }
    
    div.dataTables_wrapper div.dataTables_paginate ul.pagination {
        justify-content: center !important;
    }
    
    .btn-profile {
        display: inline-block;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border: 1px solid transparent;
        padding: 0.3rem 0.45rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 0.25rem;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        cursor: pointer;
        color: #212529;
        background-color: #f8f9fa;
        border-color: #f8f9fa;
        font-size: 13px;
        font-weight: 600;
    }
    
    .btn-profile:hover {
        color: #333;
        background-color: #FFF;
        border-color: #dae0e5;
    }
    
    
    .btn-profile img {
        margin-right: 5px;
    }
      
    .selected-tags {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 10px;
    }
      
    .tag {
        background-color: #4E21E6;
        background-image: linear-gradient(-45deg, #4E21E6 20%, #BA84F5);
        background-size: 100%;
        background-repeat: repeat;
        color: white;
        padding: 0.5rem 1rem;
        border-radius: 20px;
        display: flex;
        align-items: center;
        font-size: 14px;
    }
    
    .tag .remove {
        margin-left: 10px;
        cursor: pointer;
    }
      

    .inf-table thead tr{
        
    }

    .inf-table thead tr th{
        padding-bottom: 10px;
    }
    
}

.filters{
    .filters-select{

        .select-group{
            display: flex;
            border: 1px solid #222;
            border-radius: 0.25rem;
            margin-right: 1rem;

            button:nth-child(1){
                background: var(--main-bg);
                font-size: 14px;
                padding: 8px 16px;
                color: #999;
                border-radius: 0.15rem 0 0 0.15rem;
            }

            .multi-select { 
                
                .dropdown-container{
                    border-radius: 0;
                    height: 40px;
                    background: #222;
                    font-size: 14px;
                    color: #555;
                    font-weight: 500;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    max-width: 300px;
                    min-width: 150px;
                    border: 1px solid #222;

                    &:focus, &:focus-within{
                        box-shadow: none;
                        outline: none;
                    }
                }

                .dropdown-heading{
                    height: auto;
                    padding: auto;
                    padding: 8px 14px 8px 20px;
                    cursor: pointer;

                    .dropdown-heading-dropdown-arrow{
                        // height: 20px;
                        // width: 20px;
                        // margin-left: 50px;
                    }
                }

                .dropdown-content *{
                }

            }

        }

        select {
            border-radius: 50px !important;
            height: 40px;
            border: 1px solid #F4F4FF;
            background: #F4F4FF;
            font-size: 14px;
            color: #555;
            font-weight: 600;
            padding: 8px 30px 10px 20px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background: url("https://img.icons8.com/ios-glyphs/30/expand-arrow--v1.png") #F4F4FF no-repeat 98.5% !important;
            background: url("https://img.icons8.com/ios-glyphs/30/expand-arrow--v1.png") #F4F4FF no-repeat calc(100% - 10px) !important;
            background-size: 13px 13px !important;

            &:active, &:focus{
                outline: none !important;
            }

            &:after{
                display: none;
            }

            option{
                font-weight: 400;
                min-height: 1.75em;
                padding: 5px 6px;
                color: #333;
                border-radius: 10px;
            }
        }
    }
}

@media (max-width: 768px) {
    .filters{
        .filters-select{
                .select-group{
                    margin-bottom: 1rem;
                }
            }
        }
}