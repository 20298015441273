.auth-screens {
    background-color: #000;
    min-height: 100vh;
    display: flex;

    .heading{
        font-family: inherit;
        color: var(--main-color);
        // font-size: 3.5rem;
        font-size: calc(100% + 2.25vw);
        font-weight: 600;
        line-height: 1.5;
        margin-bottom: 2rem;
    }

    .subheading{
        color: #e1e1e1;
        // font-size: 1rem;
        font-size: calc(100% + .1vw);
        font-weight: 400;
        letter-spacing: .25px;
        line-height: 2;
        margin-bottom: 0px;
    }

    .subheading span{
        font-weight: 600;
    }

    .form-wrapper {
        max-width: 30rem;
        width: 100%;
    }

    .stretch-items {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .section-right {
    }

    .section-right {
        .form-section{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            width: 100%;

            h3 {
                font-family: "Oswald", sans-serif;
                word-wrap: break-word;
                font-size: 2.7rem;
                line-height: 1.4;
                color: var(--main-color);
                font-weight: 600;
                letter-spacing: 0.75px;
                margin-bottom: 5px;
            }
    
            h6 {
                line-height: 1.6;
                font-size: 1rem;
                font-weight: 400;
                color: #FFF;
                margin-bottom: 0px;
            }
        }
    }

    form {

        hr {
            margin: 1.5rem 0rem;
            height: 0.01rem;
            background: #e3e8ef;
        }

        .form-group {
            position: relative;
            margin-bottom: 0px;

            label {
                cursor: pointer;
                font-weight: 500;
                font-size: 12px;
                color: #CCC;
                margin-bottom: 12px;
                letter-spacing: .25px;

                svg {
                    color: #777;
                    margin-right: 10px;
                    margin-top: 5px;
                    transition: .25s;
                }
            }

            &.focus {
                label {
                    font-size: 15px;
                    top: -12px;
                    font-weight: 500;
                    color: #004679;
                }

                svg {
                    color: #004679;
                }
            }

            .input-icon {
                position: absolute;
                left: 1rem;
                bottom: 1.2rem;

                svg {
                    color: #666;
                    font-size: 22px;
                }
            }

            input, select {
                color: #CCC !important;
                font-weight: 500;
                letter-spacing: .25px;
                background: #000 !important;
                border: 1px solid #000 !important;
                border-radius: .3rem !important;
                height: 3rem !important;
                padding: 8px 14px !important;
                font-size: 15px !important;
                width: 100%;

                &::placeholder {
                    font-weight: 500;
                    font-size: 15px;
                    color: #555;
                }

                &:active,
                &:hover,
                &:focus,
                &:focus-visible {
                    border: 1px solid #222 !important;
                    outline: none;
                }
            }
        }
        
        .fp {
            // color: #333;
            font-weight: 400;
            font-size: 14px;
        }

        .btn-main-auth {
            border-radius: 9px;
            background: var(--main-color);
            color: #111;
            transition: 350ms ease;
            font-size: 15px;
            font-weight: 500;
            text-align: center;
            letter-spacing: 0.06em;
            border: 1px solid var(--main-color);
            padding: 16px 30px;
            width: 100%;
            text-transform: uppercase;

            &:hover {
                box-shadow: rgb(76 78 100 / 100%) 0px 6px 18px -8px;
                background-color: var(--main-dark-color);
                color: #000;
            }
        }

        .nlp input {
            padding: 0.5rem 0.875rem 0.5rem 0.875rem !important;
        }

        &.login-form{
            input {
                padding: 13px 14px 13px 3.2rem !important;
                height: 3.75rem !important;
                border-radius: .5rem !important;
            }
        }
    }

    .section-left {
        position: relative;

        .bg-overlay {
            height: 100vh;
            display: flex;
            flex-direction: column;
            position: relative;
            justify-content: space-between;
            padding: 5rem 3rem;
        }

        .section-left-content {
            h2 {
                color: #FFF;
                font-weight: 800;
                margin-bottom: 1.5rem;
                font-size: 2.4rem;
            }

            h5 {
                color: #f1f1f1;
                font-weight: 300;
                font-size: 1.3rem;
                word-spacing: 2px;
            }
        }
    }

    .text {
        color: hsla(0, 0%, 100%, 0.45);
        font-weight: 300;
        line-height: 2;
    }

    @media screen and (min-width: 786px) {
        body {
            overflow-y: hidden;
        }
    }

    @media screen and (max-width: 786px) {
        .login {
            max-width: 100%;
        }
    }

    @media screen and (max-width: 768px) {
        .heading{
            font-size: 1.75rem;
            margin-bottom: 1.25rem;
        }

        form {
            h3 {
                font-size: 2.25rem;
                letter-spacing: 0.25px;
                margin-bottom: 0px;
            }

            h6 {
                font-size: 1.1rem;
                margin-bottom: 30px;
            }

            hr {
                display: none;
            }
        }

        .section-left-content {
            h2 {
                font-size: 1.15rem;
            }
        }

        .section-left {
            background: var(--main-color);
        }

        .section-right {
        }

        .bg-overlay {
            height: 100%;
            padding: 1.5rem .5rem .5rem .5rem;
        }

        .logo-sm {
            display: none;
        }

        .section-right form {
            margin-bottom: .5rem;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #000 !important;
        -webkit-box-shadow: 0 0 0 30px #FFF inset !important;
    }
}

.terms {
    .content {
        h2 {
            color: black;
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 1.75rem;
            margin-bottom: 0.25rem;
        }

        p {
            color: #666;
            font-size: 1rem;
            line-height: 1.8;
        }
    }
}

.privacy {
    .content {
        h2 {
            color: black;
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 1.75rem;
            margin-bottom: 0.5rem;
        }

        p, ul {
            color: #666;
            font-size: 1rem;
            line-height: 1.5;
            margin-bottom: 1rem;
        }
    }
}

.btn-main-auth-outline {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    background: #000;
    color: #FFF;
    transition: 350ms ease;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.06em;
    border: 1px solid #FFF;
    padding: 16px 10px;
    width: 100%;

    span{
        margin-left: 10px;
    }

    &.active {
        background-color: var(--main-color);
        border: 1px solid var(--main-color);
        color: #000;
    }

    &.nonactive {
        opacity: .3;
    }

    &:hover {
        box-shadow: rgb(76 78 100 / 100%) 0px 6px 18px -8px;
        background-color: var(--main-color);
        border: 1px solid var(--main-color);
        color: #000;
        opacity: 1;
    }
}