
main.settings ._1Fq_tZ_l7SwH_r_a_SNOtA{
    font-family: 'Hanken Grotesk', sans-serif;
}

main.settings ._1Fq_tZ_l7SwH_r_a_SNOtA ._1Ou1tAFc1VCD4TNa0XwE7z {
    padding: 0%;
}

main.settings ._2oSbenaQCQZPXOD75Sj6nE {
    margin: 0;
}

main.settings .Icon--picture{
    display: none !important;
}

main.settings ._1MKAF96uXJHSW-a1nWoriQ {
    width: 100%;
    margin: 10px 0px 0px 0px;
}

main.settings ._1Fq_tZ_l7SwH_r_a_SNOtA ._10Kns8R3VdHSGFrESIz-4B{
    margin: 10px 0px 0px 0px;
}

main.settings .Icon--trash{
    fill: 'maroon';
}

._6IMSWtd3wtGGn2KgOcZIK{
    background-color: #FFF;
    padding: 10px 0px;
}